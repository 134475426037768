<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin bộ phụ kiện tặng kèm'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <div>
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Bộ phụ kiện tặng kèm
                  </p>
                </div>
                <b-row>
                  <b-col cols="3">
                    <b-form-group id="input-group-name">
                      <label for="input-code">Tên bộ phụ kiện:</label>
                      <b-form-input
                        size="sm"
                        id="input-name"
                        v-model="name"
                        placeholder="Nhập tên"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-code">
                      <label for="input-code">Mã bộ phụ kiện:</label>
                      <b-form-input
                        size="sm"
                        id="input-code"
                        v-model="code"
                        placeholder="Nhập mã"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-start-date">
                      <label for="input-start-date">Ngày bắt đầu:</label>
                      <date-picker
                        placeholder="Từ ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="startDate"
                      ></date-picker>
                    </b-form-group>

                    <b-form-group id="input-group-end-date">
                      <label for="input-end-date">Ngày kết thúc:</label>
                      <date-picker
                        placeholder="Đến ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="endDate"
                      ></date-picker>
                    </b-form-group>
                  </b-col>

                  <b-col cols="5">
                    <label style="font-weight: 600" for="input-description"
                      >Mô tả:</label
                    >
                    <b-form-textarea
                      id="textarea-description"
                      v-model="description"
                      placeholder="Nhập mô tả"
                      rows="13"
                    ></b-form-textarea>
                  </b-col>
                  <b-col cols="2">
                    <label style="font-weight: 600" for="input-status"
                      >Trạng thái:</label
                    >
                    <b-form-select
                      size="sm"
                      id="input-3"
                      v-model="selectedStatus"
                      :options="listStatus"
                      required
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    ></b-form-select>
                  </b-col>
                  <b-col cols="2"></b-col>
                </b-row>
                <b-row>
                  <b-form-group
                    id="input-group-search"
                    class="col-md-12 mt-4 pr-0"
                  >
                    <label for="input-code">Tìm sản phẩm:</label>
                    <b-col md="12" class="row pr-0">
                      <b-col md="6" class="p-0">
                        <vue-autosuggest
                          v-model="searchProductAccessory"
                          :suggestions="filteredOptionsProduct"
                          @selected="onSelectedProduct"
                          :limit="10"
                          @input="onInputChangeProduct"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Nhập tên sản phẩm',
                            style: 'border-radius:0px!important',
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                          <template slot-scope="{ suggestion }">
                            <div>{{ suggestion.item.productName }}</div>
                          </template>
                        </vue-autosuggest>
                      </b-col>
                      <b-col md="2" class="p-0">
                        <b-form-select
                          id="select-product"
                          v-model="selectTypeSearch"
                          :options="listTypeSearch"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="debounceInput"
                        ></b-form-select>
                      </b-col>
                      <b-col md="2" class="p-0">
                        <b-form-select
                          id="select-product"
                          v-model="selectTypeOfProduct"
                          :options="listTypeOfProduct"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="debounceInput"
                        ></b-form-select>
                      </b-col>
                      <b-col md="2" class="pr-0">
                        <div class="d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-secondary font-weight-bolder btn-sm"
                            @click="showModalImportProduct"
                          >
                            <i
                              style="font-size: 1rem"
                              class="far fa-file-excel"
                            ></i
                            >Nhập SP từ excel
                          </button>
                        </div>
                      </b-col>
                    </b-col>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-col cols="12" md="12">
                    <div>
                      <!-- <table
                        class="table table-bordered table-vertical-center table-hover"
                      >
                        <thead>
                          <tr>
                            <th class="name headerTable">Mã sp</th>
                            <th class="value headerTable">Tên sp</th>
                            <th class="code headerTable">Giá vốn</th>
                            <th class="code headerTable">Giá bán</th>
                            <th class="code headerTable">Giá thu lại</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody
                          v-for="(item, index) in accessoryItems"
                          :key="index"
                        >
                          <AccessoryItem
                            v-bind:productItem="item"
                            v-bind:type="accessoryType"
                            v-on:deleteItem="deleteItemInArray"
                            v-on:update="updateItem"
                            v-bind:index="index"
                          />
                        </tbody>
                      </table> -->
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fieldProduct"
                        :items="accessoryItems"
                        v-if="renderComponent"
                        :per-page="10"
                        :current-page="currentPageTable"
                        id="my-table-product"
                      >
                        <template v-slot:cell(importPrice)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.importPrice"
                            v-mask="mask"
                            @change="forceRerender"
                          ></b-input>
                        </template>
                        <template v-slot:cell(originalPrice)="row">
                          <span class="text-right">{{
                            formatMoney(row.item.originalPrice)
                          }}</span>
                        </template>
                        <template v-slot:cell(sellingPrice)="row">
                          <span class="text-right">{{
                            formatMoney(row.item.sellingPrice)
                          }}</span>
                        </template>
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-danger"
                            @click="deleteItemInArray(row.item.productId)"
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </b-table>
                      <b-pagination
                        v-show="accessoryItems.length > 10"
                        v-model="currentPageTable"
                        :total-rows="rowsTable"
                        :per-page="10"
                        aria-controls="my-table-product"
                        align="right"
                      ></b-pagination>
                    </div>
                  </b-col>
                  <b-col cols="6" md="4"></b-col>
                </b-row>

                <div class="separator separator-dashed my-5"></div>

                <div>
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Bộ phụ kiện cấu hình cho
                  </p>
                </div>

                <b-row>
                  <b-col md-4>
                    <b-form-group
                      id="form-group-color"
                      label-for="input-color"
                      label="Danh mục"
                    >
                      <b-input-group>
                        <vue-multi-select
                          style="margin-bottom: 2rem"
                          v-model="selectedCategory"
                          :options="categories"
                          placeholder="Chọn danh mục"
                          option-label="name"
                        ></vue-multi-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                </b-row>
                <b-row>
                  <b-form-group id="input-group-search" class="col-md-12 pr-0">
                    <label for="input-code">Tìm sản phẩm:</label>
                    <b-col md="12" class="row pr-0">
                      <b-col md="6" class="p-0">
                        <vue-autosuggest
                          v-model="searchProductFor"
                          :suggestions="filteredOptionsProductFor"
                          @selected="onSelectedProductFor"
                          :limit="10"
                          @input="onInputChangeProductFor"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Nhập tên sản phẩm',
                            style: 'border-radius:0px!important',
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                          <template slot-scope="{ suggestion }">
                            <div>{{ suggestion.item.productName }}</div>
                          </template>
                        </vue-autosuggest>
                      </b-col>
                      <b-col md="2" class="p-0">
                        <b-form-select
                          id="select-product"
                          v-model="selectTypeSearchFor"
                          :options="listTypeSearch"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="debounceInputFor"
                        ></b-form-select>
                      </b-col>
                      <b-col md="2" class="p-0">
                        <b-form-select
                          id="select-product"
                          v-model="selectTypeOfProductFor"
                          :options="listTypeOfProduct"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="debounceInputFor"
                        ></b-form-select>
                      </b-col>
                      <b-col md="2" class="pr-0">
                        <div class="d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-secondary font-weight-bolder btn-sm"
                            @click="showModalImportProductFor"
                          >
                            <i
                              style="font-size: 1rem"
                              class="far fa-file-excel"
                            ></i
                            >Nhập SP từ excel
                          </button>
                        </div>
                      </b-col>
                    </b-col>
                  </b-form-group>
                </b-row>

                <b-row>
                  <b-col cols="12" md="8">
                    <div>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fields"
                        :items="accessoryProductItems"
                        :per-page="10"
                        :current-page="currentPageMain"
                        id="my-table-main"
                      >
                        <template v-slot:cell(originalPrice)="row">
                          <span class="text-right">{{
                            formatMoney(row.item.originalPrice)
                          }}</span>
                        </template>
                        <template v-slot:cell(sellingPrice)="row">
                          <span class="text-right">{{
                            formatMoney(row.item.sellingPrice)
                          }}</span>
                        </template>
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-danger"
                            @click="showDeleteAlert(row.item)"
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </b-table>
                      <b-pagination
                        v-show="accessoryProductItems.length > 10"
                        v-model="currentPageMain"
                        :total-rows="rowsMain"
                        :per-page="10"
                        aria-controls="my-table-main"
                        align="right"
                      ></b-pagination>
                    </div>
                  </b-col>
                  <b-col cols="6" md="4"></b-col>
                </b-row>
              </b-container>
            </b-form>
            <b-modal
              ref="import-product-modal"
              hide-footer
              title="Thêm sản phẩm từ Excel"
              id="modal-prevent-closing"
              size="lg"
            >
              <b-row>
                <b-col>
                  <div class="form-group">
                    <span>
                      <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                      đúng format, hoặc có thể tải ở
                      <a :href="urlExcel">đây</a>
                    </span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span> <b>Bước 2:</b> Tiến hành Import </span>
                  <div style="padding-top: 15px">
                    <b-form-file
                      placeholder="Hãy chọn một tập tin"
                      @change="selectFile"
                      v-model="file"
                      ref="file-input"
                      class="mb-2"
                    ></b-form-file>
                  </div>
                </b-col>
              </b-row>
              <b-container
                class="mt-4 pl-0 pr-0"
                v-show="importItems.length > 0"
              >
                <h5>
                  Danh sách sản phẩm từ file excel( Tổng:
                  {{ importItems.length }} sản phẩm)
                </h5>
                <b-table
                  id="my-table"
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fieldImport"
                  :items="importItems"
                  :per-page="10"
                  :current-page="currentPage"
                >
                  <template v-slot:cell(originalPrice)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.originalPrice)"></span>
                    </div>
                  </template>

                  <template v-slot:cell(sellingPrice)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.sellingPrice)"></span>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-show="importItems.length > 10"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="10"
                  aria-controls="my-table"
                ></b-pagination>
              </b-container>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="validData"
                >Lưu</b-button
              >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hideModalImportProduct"
                >Hủy</b-button
              >
            </b-modal>
            <b-modal
              ref="import-product-for-modal"
              hide-footer
              title="Thêm sản phẩm từ Excel"
              id="modal-prevent-closing"
              size="lg"
            >
              <b-row>
                <b-col>
                  <div class="form-group">
                    <span>
                      <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                      đúng format, hoặc có thể tải ở
                      <a :href="urlExcelFor">đây</a>
                    </span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span> <b>Bước 2:</b> Tiến hành Import </span>
                  <div style="padding-top: 15px">
                    <b-form-file
                      placeholder="Hãy chọn một tập tin"
                      @change="selectFileFor"
                      v-model="fileFor"
                      ref="file-input"
                      class="mb-2"
                    ></b-form-file>
                  </div>
                </b-col>
              </b-row>
              <b-container
                class="mt-4 pl-0 pr-0"
                v-show="importItemFor.length > 0"
              >
                <h5>
                  Danh sách sản phẩm từ file excel( Tổng:
                  {{ importItemFor.length }} sản phẩm)
                </h5>
                <b-table
                  id="my-table"
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fieldImportFor"
                  :items="importItemFor"
                  :per-page="10"
                  :current-page="currentPageFor"
                >
                  <template v-slot:cell(originalPrice)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.originalPrice)"></span>
                    </div>
                  </template>

                  <template v-slot:cell(sellingPrice)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.sellingPrice)"></span>
                    </div>
                  </template>
                  <template v-slot:cell(returnPrice)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.returnPrice)"></span>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-show="importItemFor.length > 10"
                  v-model="currentPageFor"
                  :total-rows="rowsFor"
                  :per-page="10"
                  aria-controls="my-table"
                ></b-pagination>
              </b-container>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="validDataFor"
                >Lưu</b-button
              >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hideModalImportProductFor"
                >Hủy</b-button
              >
            </b-modal>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-container class="bv-example-row">
              <b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="onSubmit"
                  >Lưu</b-button
                >
                <router-link to="/accessories" tag="button">
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    >Hủy</b-button
                  >
                </router-link>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
import xlsx from 'xlsx';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import VueMultiSelect from 'vue-simple-multi-select';
import decounce from 'debounce';
import {
  TIME_TRIGGER,
  URL_IMPORT_PRODUCT_ACCESSORY,
  URL_IMPORT_PRODUCT_FOR_ACCESSORY,
} from './../../../utils/constants';
import { currencyMask, unMaskPrice } from './../../../utils/common';

export default {
  data() {
    return {
      selectedCategory: [],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      startDate: '',
      endDate: '',
      accessoryType: 1,
      fields: [
        {
          key: 'productCode',
          label: 'Mã',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'productName',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
      listProductFor: [],
      filteredOptionsProductFor: [],
      optionsProductFor: [
        {
          data: [],
        },
      ],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      code: '',
      name: '',
      description: '',
      searchProductAccessory: '',
      accessoryItems: [],
      accessoryProductItems: [],
      searchProductFor: '',
      categories: '',
      stores: '',
      listStatus: [
        {
          id: 1,
          name: 'Hoạt động',
        },
        {
          id: 2,
          name: 'Đã khóa',
        },
      ],
      selectedStatus: 1,
      isSearching: false,
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 2,
          name: 'Sản phẩm',
        },
      ],
      selectTypeSearch: 2,
      listTypeOfProduct: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeOfProduct: 0,
      selectTypeSearchFor: 1,
      selectTypeOfProductFor: 0,
      importItems: [],
      file: null,
      excellist: [],
      progress: 0,
      uploading: false,
      fieldImport: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
        {
          key: 'originalPrice',
          label: 'Giá vốn',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
        },
        {
          key: 'returnPrice',
          label: 'Giá thu lại',
        },
      ],
      currentPage: 1,
      importItemFor: [],
      fileFor: null,
      excellistFor: [],
      currentPageFor: 1,
      fieldImportFor: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
        {
          key: 'originalPrice',
          label: 'Giá vốn',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
        },
      ],
      urlExcel: URL_IMPORT_PRODUCT_ACCESSORY,
      urlExcelFor: URL_IMPORT_PRODUCT_FOR_ACCESSORY,
      currentPageMain: 1,
      currentPageTable: 1,
      fieldProduct: [
        {
          key: 'productCode',
          label: 'Mã SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'productName',
          label: 'Tên SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'originalPrice',
          label: 'Giá vốn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'importPrice',
          label: 'Giá thu lại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
      renderComponent: true,
      mask: currencyMask,
    };
  },
  components: {
    KTCodePreview,
    // AccessoryItem,
    datePicker,
    VueMultiSelect,
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
    rowsFor() {
      return this.importItemFor.length;
    },
    rowsTable() {
      return this.accessoryItems.length;
    },
    rowsMain() {
      return this.accessoryProductItems.length;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Bộ phụ kiện tặng kèm', route: '/accessories' },
      {
        title: 'Thêm mới bộ phụ kiện tặng kèm',
        route: '/accessories/add-accessory',
      },
    ]);
    const copyId = this.$route.query.id;
    if (copyId) {
      this.getInfoById(copyId);
    }
  },
  created() {
    //this.fetchProduct();
    this.getCategories();
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    getCategories() {
      ApiService.setHeader();
      ApiService.query('category')
        .then(({ data }) => {
          if (data.status === 1) {
            this.categories = data.data.list_caterogy;
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    updateItem(item) {
      this.accessoryItems.forEach((element, index) => {
        if (element.productId === item.productId) {
          this.accessoryItems[index].importPrice = item.importPrice;
        }
      });
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          for (let i = 0; i < this.accessoryProductItems.length; i++) {
            if (this.accessoryProductItems[i].productId === item.productId) {
              this.accessoryProductItems.splice(i, 1);
            }
          }
        }
      });
    },
    deleteItemInArray(productId) {
      for (var i = 0; i < this.accessoryItems.length; i++) {
        if (this.accessoryItems[i].productId === productId) {
          this.accessoryItems.splice(i, 1);
        }
      }
    },
    fetchProduct(textSearch) {
      this.listProduct = [];
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch/search-by-type?searchProduct=${textSearch}&typeSearch=${this.selectTypeSearch}&typeOfProduct=${this.selectTypeOfProduct}`
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        if (textSearch !== this.searchProductAccessory) {
          let nextTextSearch = this.searchProductAccessory;
          this.fetchProduct(nextTextSearch);
        }
      });
    },
    fetchProductFor(textSearch) {
      this.listProduct = [];
      this.optionsProductFor[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch/search-by-type?searchProduct=${textSearch}&typeSearch=${this.selectTypeSearchFor}&typeOfProduct=${this.selectTypeOfProductFor}`
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProductFor[0].data.push(element);
        });
        this.filteredOptionsProductFor = [
          {
            data: this.optionsProductFor[0].data,
          },
        ];
        if (textSearch !== this.searchProductFor) {
          let nextTextSearch = this.searchProductFor;
          this.fetchProduct(nextTextSearch);
        }
      });
    },
    onSelectedProduct(option) {
      this.searchProductAccessory = '';

      let accessoryItems = {
        productId: option.item.productId,
        productName: option.item.productName,
        productCode: option.item.productCode,
        originalPrice: option.item.originalPrice,
        sellingPrice: option.item.sellingPrice,
        importPrice: 0,
      };

      this.accessoryItems.push(accessoryItems);
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProductAccessory = text;
      this.debounceInput();
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';

      let accessoryProductItem = {
        productId: option.item.productId,
        productName: option.item.productName,
        productCode: option.item.productCode,
        originalPrice: option.item.originalPrice,
        sellingPrice: option.item.sellingPrice,
      };

      this.accessoryProductItems.push(accessoryProductItem);
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;

      this.debounceInputFor();
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onSubmit: async function () {
      let isActive = 1;
      if (this.selectedStatus === 2) {
        isActive = 0;
      }
      this.accessoryItems.forEach((element) => {
        element.importPrice = unMaskPrice(element.importPrice);
      });
      let params = {
        name: this.name,
        code: this.code,
        description: this.description,
        //  bo phu kien kem theo
        type: 1,
        accessoryItems: this.accessoryItems,
        accessoryProductItems: this.accessoryProductItems,
        startDate: this.startDate
          ? moment(this.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        endDate: this.endDate
          ? moment(this.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        categories: this.selectedCategory,
        isActive: isActive,
      };

      ApiService.setHeader();
      ApiService.post('accessories', { params }).then((response) => {
        const { status, message } = response.data;
        if (status === 1) {
          this.$router.push({
            name: 'list-accessory',
            params: { createAccessorySuccess: true, message: 'Success' },
          });
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    debounceInput: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProductAccessory;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    debounceInputFor: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProductFor;
        this.fetchProductFor(textSearch);
      }
    }, TIME_TRIGGER),
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              productName: element['Tên sản phẩm']
                ? element['Tên sản phẩm']
                : '',
              productCode: element['Mã sản phẩm'] ? element['Mã sản phẩm'] : '',
              originalPrice: element['Giá vốn'] ? element['Giá vốn'] : 0,
              sellingPrice: element['Giá bán'] ? element['Giá bán'] : 0,
              returnPrice: element['Giá thu lại'] ? element['Giá thu lại'] : 0,
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    showModalImportProduct() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    validData() {
      let data = {
        listProduct: this.importItems,
      };
      ApiService.setHeader();
      ApiService.post('accessories/valid-import-product', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.accessoryItems.push(element);
            });
            this.hideModalImportProduct();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err;
          this.makeToastFailure(message);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFailure(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    selectFileFor(event) {
      this.importItemFor = [];
      const files = event.target.files[0];
      this.fileFor = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.fileFor = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellistFor = excellist;
          this.excellistFor.map((element) => {
            let data = {
              productName: element['Tên sản phẩm']
                ? element['Tên sản phẩm']
                : '',
              productCode: element['Mã sản phẩm'] ? element['Mã sản phẩm'] : '',
              originalPrice: element['Giá vốn'] ? element['Giá vốn'] : 0,
              sellingPrice: element['Giá bán'] ? element['Giá bán'] : 0,
              returnPrice: element['Giá thu lại'] ? element['Giá thu lại'] : 0,
            };
            this.importItemFor.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    showModalImportProductFor() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-for-modal'].show();
    },
    hideModalImportProductFor() {
      this.$refs['import-product-for-modal'].hide();
    },
    validDataFor() {
      let data = {
        listProduct: this.importItemFor,
      };
      ApiService.setHeader();
      ApiService.post('accessories/valid-import-product', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.accessoryProductItems.push(element);
            });
            this.hideModalImportProductFor();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err;
          this.makeToastFailure(message);
        });
    },
    getInfoById: async function (id) {
      const STATUS_ACCESSORY_GROUP={
        ACTIVE: 1,
        INACTIVE: 2
      }
      await ApiService.setHeader();
      await ApiService.get('accessories/' + id).then((res) => {
        let {
          name,
          code,
          description,
          startDate,
          endDate,
          accessoryItems,
          accessoryProductItems,
          isActive,
          accessoryCategories,
        } = res.data.data;
        this.name = name;
        this.code = code;
        this.description = description;
        this.startDate =
          startDate !== null ? moment(startDate).format('DD/MM/YYYY') : '';
        this.endDate =
          endDate !== null ? moment(endDate).format('DD/MM/YYYY') : '';
        this.accessoryItems = accessoryItems;
        this.accessoryProductItems = accessoryProductItems;
        this.selectedStatus = isActive === STATUS_ACCESSORY_GROUP.ACTIVE ? STATUS_ACCESSORY_GROUP.ACTIVE : STATUS_ACCESSORY_GROUP.INACTIVE;
        this.selectedCategory = accessoryCategories;
        });
    },
  },
};
</script>

<style>
.rounded {
  border-radius: 0 !important;
}

.form-group label {
  font-weight: 600;
}
</style>
